@import "../../GlobalStyles.scss";

.wrapper{
    width: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 50px;
    @media screen and (width < 700px) {
        flex-direction: column;
        gap: 10px;
    }
}

.logo{
    font-size: 25px;
}

.links{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 30%;
    gap: 10px;
    a{
        color: inherit ;
        text-decoration: none;
        padding: 10px;
        transition: all 0.15s ease-in-out;
        &:hover{
            text-shadow: $textGlowing;
            transform: scale(1.35);

        }
    }
}

.socials{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 30px
}